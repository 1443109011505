import React, { memo } from 'react';

const ResultNodeData: React.FC<{ text: string; success: boolean }> = (props) => {
  const { text, success } = props;
  return (
    <div
      style={{
        display: 'flex',
        padding: 5,
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: 5,
          marginLeft: 10,
        }}
      >
        <p style={{ fontWeight: 'bolder' }}>new data record</p>
        <p style={{ marginLeft: 10, marginRight: 10 }}>{text}</p>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: 5,
          marginRight: 10,
        }}
      >
        <div
          style={{
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            backgroundColor: success ? '#02DE03' : 'red',
            border: success ? '2px solid #49BF56' : '2px solid red',
          }}
        ></div>
        <p style={{ marginLeft: 10, fontWeight: 'bold', color: success ? '#02DE03' : 'red' }}>
          Hack {success ? 'successful' : 'unsuccessful'}!
        </p>
      </div>
    </div>
  );
};

export default memo(ResultNodeData);
