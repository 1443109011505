import React from 'react';
import { localization } from '../../../assets/Localization';
import { getCookie } from '../../../lib/cookieUtils';
import { PaymentFrequency } from '../../../types/PaymentFrequency';

export const getQueryStringWithUpdatedParameter = (param: string, value: string): string => {
  const originalQueryString = window.location.search;

  if (!originalQueryString.includes(param)) {
    return `${originalQueryString}${originalQueryString.length > 0 ? '&' : ''}${param}=${encodeURIComponent(value)}`;
  } else {
    return originalQueryString.replace(new RegExp('('+param+'=)[^&]+'), `$1${encodeURIComponent(value)}`);
  }
}

const PaymentFrequencies: React.FC<{ paymentFrequencies: string | null }> = ({ paymentFrequencies }) => {
  const language = getCookie('locale');

  const paymentFrequencyHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    window.location.search = getQueryStringWithUpdatedParameter('paymentFrequency', event.currentTarget.id);
  };

  return (
    <>
      {Object.values(PaymentFrequency).map((el) => (
        <a
          key={el}
          href="#"
          onClick={paymentFrequencyHandler}
          className={`${paymentFrequencies === el ? 'active' : ''}`}
          id={el}
        >{`${
          el.includes(PaymentFrequency.MONTHLY)
            ? localization[language || 'en-US'].monthly
            : localization[language || 'en-US'].oneTime
        }`}</a>
      ))}
    </>
  );
};

export default PaymentFrequencies;

