import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { ReactFlowProvider } from 'reactflow';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import diagramStore from './store';
import { isTouchDevice } from './utils/isTouchDevice';

const DiagramProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  React.useEffect(() => {
    document.body.style.margin = '0';

    return () => {
      document.body.style.margin = '0 auto';
    };
  }, []);

  return (
    <Provider store={diagramStore}>
      <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
        <ReactFlowProvider>
          {children}
        </ReactFlowProvider>
      </DndProvider>
    </Provider>
  );
};

export default DiagramProvider;
