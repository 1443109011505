import React, { useState } from 'react';
import useHttp from '../../../hooks/useHttp';
import { Currency } from '../../../types/Order';
import { getQueryStringWithUpdatedParameter } from './PaymentFrequencies';

const CurrencySelect: React.FC<{ selectedCurrency?: string }> = ({ selectedCurrency }) => {
  const [currency, setCurrency] = useState<Currency[]>([]);

  useHttp(
    { url: '/currency/available-currencies' },
    React.useCallback((data: Currency[]) => {
      setCurrency(data);
    }, []),
  );

  const currencyChangeHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    window.location.search = getQueryStringWithUpdatedParameter('currency', event.currentTarget.textContent!);
  };

  return (
    <>
      {currency.map((el) => (
        <a key={el} href="#" onClick={currencyChangeHandler} className={`${selectedCurrency === el ? 'active' : ''}`}>
          {el}
        </a>
      ))}
    </>
  );
};

export default CurrencySelect;

