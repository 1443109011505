type TimeUnit = 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years';

export const dateFormatting = (value: number, unit: TimeUnit): string => {
  const date = new Date();

  switch (unit) {
    case 'minutes':
      date.setMinutes(date.getMinutes() + value);
      break;
    case 'hours':
      date.setHours(date.getHours() + value);
      break;
    case 'days':
      date.setDate(date.getDate() + value);
      break;
    case 'weeks':
      date.setDate(date.getDate() + 7 * value);
      break;
    case 'months':
      date.setMonth(date.getMonth() + value);
      break;
    case 'years':
      date.setFullYear(date.getFullYear() + value);
      break;
    default:
      throw new Error('Invalid time unit');
  }

  return date.toUTCString();
};
