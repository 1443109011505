import { Node } from 'reactflow';
import { call, put } from 'redux-saga/effects';
import { setDeleteNodes, setDiagram, setEdges, setNodes } from '../../entities/diagram';
import {
  CreateNodePayload,
  UpdateNodePayload,
  DeleteNodePayload,
  Diagram,
  ConnectionPayload,
  DiagramConnection,
  UpdateConnectionPayload,
  DeleteConnectionPayload,
} from '../../../types';
import {
  requestCreateDiagram,
  requestGetDiagram,
  requestCreateNode,
  requestDeleteNode,
  requestUpdateNode,
  requestCreateConnection,
  requestUpdateConnection,
  requestDeleteConnection,
} from '../requests/diagram';
import { diagramMapper } from '../../../utils/diagramMapper';

// Diagram handlers
export function* handleGetDiagram(action: { payload: string }): Generator<object, void, Diagram> {
  try {
    const response = action.payload ? yield call(requestGetDiagram, action.payload) : yield call(requestCreateDiagram);
    const mappedData = diagramMapper(response);
    yield put(setDiagram(mappedData));
  } catch (error) {
    console.log(error, 'create diagram error');
  }
}

// Node handlers
export function* handleCreateNode(action: { payload: CreateNodePayload }): Generator<object, void, Node> {
  try {
    const response = yield call(requestCreateNode, action.payload);
    yield put(setNodes(response));
    yield call(handleGetDiagram, { payload: action.payload.diagramId });
  } catch (error) {
    console.log(error, 'this is error');
  }
}

export function* handleUpdateNode(action: { payload: UpdateNodePayload }): Generator<object, void, Node> {
  try {
    const response = yield call(requestUpdateNode, action.payload);
    yield put(setNodes(response));
    yield call(handleGetDiagram, { payload: action.payload.diagramId });
  } catch (error) {
    console.log(error, 'this is error');
  }
}

export function* handleDeleteNode(action: { payload: DeleteNodePayload }): Generator<object, void, Node> {
  try {
    const response = yield call(requestDeleteNode, action.payload);
    console.log(response, 'this is response');
    yield put(setDeleteNodes({ nodeId: action.payload.nodeId }));
    yield call(handleGetDiagram, { payload: action.payload.diagramId });
  } catch (error) {
    console.log(error, 'this is error');
  }
}

// Connection handlers
export function* handleCreateConnection(action: {
  payload: ConnectionPayload;
}): Generator<object, void, DiagramConnection> {
  try {
    const response = yield call(requestCreateConnection, action.payload);
    yield put(setEdges(response));
    yield call(handleGetDiagram, { payload: action.payload.diagramId });
  } catch (error) {
    console.log(error, 'this is error');
  }
}

export function* handleUpdateConnection(action: {
  payload: UpdateConnectionPayload;
}): Generator<object, void, DiagramConnection> {
  try {
    const response = yield call(requestUpdateConnection, action.payload);
    yield put(setEdges(response));
    yield call(handleGetDiagram, { payload: action.payload.diagramId });
  } catch (error) {
    console.log(error, 'this is error');
  }
}

export function* handleDeleteConnection(action: {
  payload: DeleteConnectionPayload;
}): Generator<object, void, DiagramConnection> {
  try {
    yield call(requestDeleteConnection, action.payload);
    yield put(setEdges(action.payload));
    yield call(handleGetDiagram, { payload: action.payload.diagramId });
  } catch (error) {
    console.log(error, 'this is error');
  }
}
