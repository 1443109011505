import { Box, TextField } from '@mui/material';
import React from 'react';

type InputTextProps = {
  title: string;
  type: string;
  isRequired: boolean;
  id: string;
  defaultValue?: string;
  className?: string;
};

const style = {
  '& .MuiInputBase-input': {
    border: 'unset',
  },
};

const InputText = React.forwardRef<HTMLInputElement, InputTextProps>((props, ref) => {
  return (
    <Box className={props.className ?? 'newsletter-form__name'}>
      <TextField
        variant="standard"
        label={props.title}
        type={props.type}
        id={props.id}
        inputRef={ref}
        required={props.isRequired}
        defaultValue={props.defaultValue}
        sx={style}
      />
    </Box>
  );
});

InputText.displayName = 'InputText';

export default InputText;

