const shoppingCart = {
  en: {
    title: 'Shopping Cart',
    product: 'Product',
    shortDescription: 'Description',
    moreDetails: 'More details',
    currency: 'Currency',
    checkout: 'Continue to Checkout',
    total: 'Total',
    revert: 'Revert change',
    toCart: 'Go to Cart',
    emptyCart: 'Your cart is currently empty!',
    addToCart: 'Add to cart',
    changeShoppingCart: 'Change Shopping Cart',
    saveShoppingCart: 'Save Shopping Cart',
    cyberSecurityGuide: 'Cyber security guide',
    syncWithTrainings: 'Sync with trainings',
    shoppingCartNotFound: 'This shopping cart does no longer exists',
    termsOfUse: 'Terms of use',
    privacyPolicy: 'Privacy policy',
    and: 'and',
  },
  de: {
    title: 'Einkaufswagen',
    product: 'Produkt',
    shortDescription: 'Beschreibung',
    moreDetails: 'Weitere Details',
    currency: 'Währung',
    checkout: 'Weiter zur Kasse',
    total: 'Total',
    revert: 'Wiederherstellen',
    toCart: 'Zum Einkaufswagen',
    emptyCart: 'Dein Einkaufswagen ist derzeit leer!',
    addToCart: 'In den Einkaufswagen',
    changeShoppingCart: 'Einkaufswagen ändern',
    saveShoppingCart: 'Einkaufswagen speichern',
    cyberSecurityGuide: 'Cyber Security Leitfaden',
    syncWithTrainings: 'Mit Trainings synchronisieren',
    shoppingCartNotFound: 'Dieser Einkaufswagen existiert nicht mehr',
    termsOfUse: 'AGB',
    privacyPolicy: 'Datenschutzerklärung',
    and: 'und',
  },
};

export default shoppingCart;
