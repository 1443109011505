import { Edge } from 'reactflow';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface XY {
  x: number;
  y: number;
}

export enum NodeType {
  CIPHER_TEXT_INPUT = 'CIPHER_TEXT_INPUT',
  ASCII_INPUT = 'ASCII_INPUT',
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT',
  XOR = 'XOR',
  KEY = 'KEY',
  AES_DECRYPT = 'AES_DECRYPT',
  RESULT = 'RESULT',
}

type NodeTypeLabel = {
  [key in NodeType]: string;
};

export const nodeTypeLabel: NodeTypeLabel = {
  [NodeType.CIPHER_TEXT_INPUT]: 'cipher text',
  [NodeType.ASCII_INPUT]: 'ascii input',
  [NodeType.AND]: 'AND',
  [NodeType.OR]: 'OR',
  [NodeType.NOT]: 'NOT',
  [NodeType.XOR]: 'XOR',
  [NodeType.KEY]: 'key',
  [NodeType.AES_DECRYPT]: 'AES decrypt',
  [NodeType.RESULT]: 'result',
};

export interface CreateNodePayload {
  diagramId: string;
  type: NodeType;
  position: XY;
}

export interface UpdateNodePayload extends DeleteNodePayload {
  position: XY;
  props: any;
}

export interface DeleteNodePayload {
  diagramId: string;
  nodeId: string;
}

export interface Port {
  id: string;
  property: string;
  locked: boolean;
}

export interface DiagramNode {
  id: string;
  type: NodeType;
  locked: boolean;
  position: XY;
  inputPorts: Port[];
  outputPorts: Port[];
  props: any;
  data: any;
}

export interface DiagramConnection {
  sourceNodeId: string;
  sourcePortId: string;
  targetNodeId: string;
  targetPortId: string;
  locked: boolean;
}

export interface Diagram {
  id: string;
  nodes: DiagramNode[];
  connections: DiagramConnection[];
  availableNodeTypes: NodeType[];
  nodeTypes: any;
  edges?: Edge[];
}

export interface ConnectionDataPayload {
  sourcePortId: string;
  targetPortId: string;
}

export interface ConnectionPayload extends ConnectionDataPayload {
  diagramId: string;
}

export interface DeleteConnectionPayload extends ConnectionPayload {
  edgeId: string;
}

export interface UpdateConnectionPayload {
  diagramId: string;
  originalConnection: ConnectionDataPayload;
  newConnection: ConnectionDataPayload;
}
