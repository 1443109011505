import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Drawer, Typography, Button, Badge, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { LanguageContext } from '../../../../providers/LanguageProvider';
import { getProductsCount, getShoppingCartSubscription } from '../../../../store/entities/order';
import { useSubscription } from '../../../../hooks/useSubscription';
import { OrderState } from '../../../../types/Order';
import { CookieType } from '../../../../types/Cookie';
import Form from '../../../common/Form';
import CustomModal from '../../../common/CustomModal';
import ContinueButton from '../../../common/ContinueButton';
import ShoppingCartList from './DrawerShoppingCartList';
import classes from './Drawer.module.css';
import { getCookie } from '../../../../lib/cookieUtils';
import { localization } from '../../../../assets/Localization';
import shoppingCart from '../../../../assets/images/shopping-cart.svg';

const drawerAnchor = 'right';

const DrawerShoppingCart: React.FC<{ goToCheckout: () => void }> = ({ goToCheckout }) => {
  const dispatch = useDispatch();
  const { language } = React.useContext(LanguageContext);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const shoppingCartId = getCookie(CookieType.SHOPPING_CART);
  const { count, isSubscribed } = useSelector((state: OrderState) => state.orders);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const { sendData, confirmationMessage } = useSubscription();

  React.useEffect(() => {
    if (!shoppingCartId) return;
    dispatch(getProductsCount(shoppingCartId));
    dispatch(getShoppingCartSubscription(shoppingCartId));
  }, [dispatch, shoppingCartId]);

  const renderModalContent = () => {
    return (
      <Form
        onSendingData={(data) => {
          sendData(`/orders/${shoppingCartId}/subscribe`, data, () =>
            dispatch(getShoppingCartSubscription(shoppingCartId)),
          );
        }}
        additional="exit-popup"
        text={''}
        title={localization[language].saveShoppingCart}
        img={<div className="downloadable-content__img"></div>}
        confirmationMessage={confirmationMessage}
      />
    );
  };

  return (
    <React.Fragment key={drawerAnchor}>
      <Box className={classes['icon-container']}>
        <img src={shoppingCart} alt="Shopping Cart" onClick={() => setIsOpen(!isOpen)} width="40" height="30" />
        <Badge badgeContent={count} color="info" style={{ right: 10 }} />
      </Box>
      <Drawer anchor={drawerAnchor} open={isOpen} onClose={() => setIsOpen(false)} disableScrollLock={true}>
        <Box className={classes['close-button']}>
          <IconButton onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          className={classes['shopping-cart-drawer']}
          role="presentation"
          onClick={() => setIsOpen(!isOpen)}
          onKeyDown={() => setIsOpen(!isOpen)}
        >
          <Typography variant="h5" component="h1" gutterBottom align="center" paddingBottom={2}>
            {localization[language].title}
          </Typography>
          {shoppingCartId ? (
            <>
              <ShoppingCartList id={shoppingCartId} />
              <ContinueButton text={localization[language].toCart} onClick={goToCheckout} />
              {!isSubscribed ? (
                <Box className={classes['save-shopping-cart-wrapper']}>
                  <Button
                    className={classes['save-shopping-cart-button']}
                    onClick={() => setShowModal((prev) => !prev)}
                  >
                    <Typography>{localization[language].saveShoppingCart}</Typography>
                  </Button>
                </Box>
              ) : null}
            </>
          ) : (
            <Box className={classes['empty-cart-wrapper']}>
              <SentimentVeryDissatisfiedIcon fontSize={'large'} />
              <Typography textAlign={'center'} marginTop={1}>
                {localization[language].emptyCart}
              </Typography>
            </Box>
          )}
        </Box>
      </Drawer>
      {showModal ? (
        <CustomModal open={showModal} title={''} content={renderModalContent()} onClose={() => setShowModal(false)} />
      ) : null}
    </React.Fragment>
  );
};

export default React.memo(DrawerShoppingCart);
