const forms = {
  en: {
    newsletterAdditionalText:
      'We’ll keep you informed. To ensure you receive only the most relevant information, you may update your preferences.',
    subSettings: 'Subscription settings',
    successSave: 'You have successfully saved your preferences.',
    successUnsubscribe: 'You have successfully unsubscribed from the newsletter.',
    confirmSubmission: 'Confirm Subscription',
    confirmationMessage: 'Please click the button to confirm your subscription.',
    preferredLanguage: 'Preferred Language',
    role: 'Role',
    selectOption: 'select an option',
    EXECUTIVE: 'Executive',
    CISO: 'CISO',
    INFORMATION_SECURITY_OFFICER: 'Information Security Officer',
    HEAD_OF_IT: 'Head of IT',
    DEPARTMENT_TEAM_LEAD: 'Department Team Lead',
    CYBER_SECURITY_EXPERT: 'Cyber Security Expert',
    SOFTWARE_DEVELOPER: 'Software Developer',
    IT_OPERATOR: 'IT Operator',
    IT_ARCHITECT: 'IT Architect',
    ENTERPRISE_ARCHITECT: 'Enterprise Architect',
    HUMAN_RESOURCES: 'Human Resources',
    TRAINER: 'Trainer',
    FREELANCER: 'Freelancer',
    OTHER: 'Other',
    experienceLevel: 'Experience Level',
    none: 'None',
    beginner: 'Beginner',
    intermediate: 'Intermediate',
    expert: 'Expert',
    iWantTo: 'I Want To',
    save: 'Save',
    unsubscribe: 'Unsubscribe',
    message: 'Message',
    subscribeToNewsletter: 'Subscribe to our newsletter',
    subscribeMessage: 'You can subscribe to very useful information in the next step',
    webinarTitle: 'Webinar',
    webinarFormName: 'Sign up',
    webinarTitleHeading: 'Webinar',
    webinarDateHeading: 'Date',
    webinarTimeHeading: 'Time',
    webinarDurationHeading: 'Duration',
    webinarActionHeading: 'Action',
    webinarDateContent: 'Sep 1, 2023',
    webinarTimeContent: '3:00 PM',
    webinarFor: 'for',
    webinarAt: 'at',
    webinarDurationContent: '60 Minutes',
    phoneTextContact:
      '(optional) We are happy to answer all your questions by phone. Please enter your phone number when you want talk to a cyber security coach.',
    phoneText:
      'Please only provide a phone number if you want us to answer your questions in an 8 minutes phone call (optional)',
    submit: 'Submit',
    sendMessage: 'Send Message',
    newsletterTitle: 'Subscribe to our newsletter',
    contactTitle: 'Contact Us',
    generalSuccessMessage: 'You have successfully submitted the form.',
    contactSuccessMessage: 'You have successfully send us the message.',
    notFound: "The page you are looking for does not exist.",
    exitPopUpTextEmptyCart: 'Wait! You forgot your cyber security guide. Get it now for free!',
    exitPopUpTextSaveCart: 'Wait! You forgot your shopping cart. Save it now to return any time!',
    confirmationMessageEmptyCart:
      'To receive your download link, please click the confirmation link in the email we just sent you. This additional step protects your privacy. Thank you!',
    confirmationMessageSaveCart: 'You have successfully saved your cart!',
    authForm: 'Sign-In',
  },
  de: {
    newsletterAdditionalText:
      'Wir halten dich auf dem Laufenden. Damit du nur die relevantesten Informationen bekommst, kannst du deine Interessen angeben.',
    subSettings: 'Abonnementeinstellungen',
    successSave: 'Du hast deine Einstellungen erfolgreich gespeichert.',
    successUnsubscribe: 'Du hast dich erfolgreich vom Newsletter abgemeldet.',
    confirmSubmission: 'Anmeldung bestätigen',
    confirmationMessage: 'Bitte klicke auf den Link, um deine Anmeldung zu bestätigen.',
    preferredLanguage: 'Bevorzugte Sprache',
    role: 'Rolle',
    selectOption: 'wähle eine option',
    EXECUTIVE: 'Exekutive',
    CISO: 'CISO',
    INFORMATION_SECURITY_OFFICER: 'Informationssicherheitsbeauftragter',
    HEAD_OF_IT: 'Leiter IT',
    DEPARTMENT_TEAM_LEAD: 'Teamleiter der Abteilung',
    CYBER_SECURITY_EXPERT: 'Cyber-Sicherheitsexperte',
    SOFTWARE_DEVELOPER: 'Softwareentwickler',
    IT_OPERATOR: 'IT-Betreiber',
    IT_ARCHITECT: 'IT-Architekt',
    ENTERPRISE_ARCHITECT: 'Unternehmensarchitekt',
    HUMAN_RESOURCES: 'Humanressourcen',
    TRAINER: 'Trainer',
    FREELANCER: 'Freiberufler',
    OTHER: 'Andere',
    experienceLevel: 'Erfahrungsniveau',
    none: 'Keiner',
    beginner: 'Anfänger',
    intermediate: 'Dazwischenliegend',
    expert: 'Experte',
    iWantTo: 'Ich möchte',
    save: 'Speichern',
    unsubscribe: 'Abbestellen',
    message: 'Nachricht',
    subscribeToNewsletter: 'Abonniere unseren Newsletter',
    subscribeMessage: 'Im nächsten Schritt kannst du sehr nützliche Informationen abonnieren',
    webinarTitle: 'Webinar',
    webinarFormName: 'Anmeldung',
    webinarTitleHeading: 'Webinar',
    webinarDateHeading: 'Datum',
    webinarTimeHeading: 'Zeit',
    webinarDurationHeading: 'Dauer',
    webinarActionHeading: 'Aktion',
    webinarDateContent: '1. September 2023',
    webinarTimeContent: '15:00 Uhr',
    webinarFor: 'für',
    webinarAt: 'um',
    webinarDurationContent: '60 Minuten',
    phoneTextContact:
      '(optional) Gerne beantworten wir alle deine Fragen telefonisch. Bitte gebe deine Telefonnummer an, wenn du mit einem Cyber Security Coach sprechen möchtest.',
    phoneText:
      'Bitte gebe nur dann deine Telefonnummer an, wenn du möchtest, dass wir deine Fragen in einem 8-minütigen Telefonat beantworten (optional).',
    submit: 'Absenden',
    sendMessage: 'Nachricht senden',
    newsletterTitle: 'Abonniere unseren Newsletter',
    contactTitle: 'Kontaktiere uns',
    generalSuccessMessage: 'Du hast das Formular erfolgreich abgeschickt.',
    contactSuccessMessage: 'Du hast die Nachricht erfolgreich an uns gesendet',
    notFound: 'Die gesuchte Seite existiert nicht.',
    exitPopUpTextEmptyCart: 'Warte! Du hast deinen Cyber Security Leitfaden vergessen. Jetzt kostenfrei herunterladen!',
    exitPopUpTextSaveCart:
      'Warte! Du hast deinen Einkaufswagen vergessen. Jetzt speichern damit du ihn jederzeit wieder aufrufen kannst!',
    confirmationMessageEmptyCart:
      'Um deinen Download Link zu erhalten, klicke bitte auf den Link in der E-Mail, die wir dir gerade geschickt haben. Dieser zusätzliche Schritt dient dem Schutz deiner Daten. Danke!',
    confirmationMessageSaveCart: 'Du hast deinen Einkaufswagen erfolgreich gespeichert!',
    authForm: 'Anmelden',
  },
};

export default forms;

