import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

const CustomHandle = ({ id, isConnectable, isInput }: { id: string; isConnectable: boolean; isInput: boolean }) => {
  if (!isInput)
    return (
      <div>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            backgroundColor: !isConnectable ? '#E2E5EA' : '#FEE68E',
            border: `2px solid ${!isConnectable ? '#BFC7D0' : 'orange'}`,
            position: 'relative',
            height: 20,
            width: 20,
            top: -10,
          }}
          id={id}
          isConnectable={isConnectable}
        />
      </div>
    );

  return (
    <div>
      <Handle
        type="target"
        position={Position.Top}
        style={{
          backgroundColor: !isConnectable ? '#E2E5EA' : '#FEE68E',
          border: `2px solid ${!isConnectable ? '#BFC7D0' : 'orange'}`,
          position: 'relative',
          height: 20,
          width: 20,
          top: 10,
        }}
        id={id}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default memo(CustomHandle);
