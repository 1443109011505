import { MarkerType } from 'reactflow';

export const marker = {
  arrow: {
    type: MarkerType?.ArrowClosed || 'arrowclosed',
    width: 15,
    height: 15,
    color: 'black',
  },
  line: {
    strokeWidth: 2,
  },
};
