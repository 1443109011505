/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react';
import { formatHex } from './AesDecryptNodeData';

export interface Block {
  map: (callback: (element: number, index: number) => JSX.Element) => JSX.Element;
}

interface CipherNodeDataProps {
  blocks: Block[];
}

const CipherNodeData: React.FC<CipherNodeDataProps> = ({ blocks }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        marginTop: 20,
      }}
    >
      {blocks.map((block: any, index: number) => (
        <div
          key={index}
          style={{
            padding: 5,
            margin: 5,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ fontWeight: 'bold', marginBottom: 10 }}>block 0{index}</div>
          <div style={{ display: 'flex' }}>
            {block.slice(0, 8).map((element: number, index: number) => (
              <div key={index} style={{ margin: 2 }}>
                <span style={{ fontSize: 22 }}>{formatHex(element)}</span>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex' }}>
            {block.slice(8, 16).map((element: number, index: number) => (
              <div key={index + 8} style={{ margin: 2 }}>
                <span style={{ fontSize: 22 }}>{formatHex(element)}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(CipherNodeData);
