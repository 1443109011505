import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { watcherSaga } from './sagas/rootSaga';
import diagramReducer from './entities/diagram';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  diagram: diagramReducer,
});

export const diagramStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    sagaMiddleware,
  ],
});
sagaMiddleware.run(watcherSaga);

export type Store = ReturnType<typeof diagramStore.getState>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof diagramStore.dispatch;
export type AppSelector<S> = (state: RootState) => S;
export default diagramStore;
