import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { watcherSaga } from './sagas/rootSaga';
import ordersReducer from './entities/order';
import invoiceReducer from './entities/invoice';
import adminOrdersReducer from './entities/adminOrders';
import orderDetailsBackOfficeReducer from './entities/orderDetailsBackOffice';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  orders: ordersReducer,
  invoice: invoiceReducer,
  adminOrders: adminOrdersReducer,
  orderDetailsBackOffice: orderDetailsBackOfficeReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    sagaMiddleware,
  ],
});
sagaMiddleware.run(watcherSaga);

export type Store = ReturnType<typeof store.getState>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppSelector<S> = (state: RootState) => S;
export default store;
