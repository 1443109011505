const general = {
  en: {
    next: 'Next',
    back: 'Back',
    tryAgain: 'Try Again',
    firstName: 'First name',
    lastName: 'Last name',
    address: 'Address',
    email: 'Email',
    gender: 'Gender',
    phone: 'Phone',
    city: 'City',
    zipCode: 'Zip / Postal code',
    country: 'Country',
    state: 'State/Canton/Province/Region',
    companyName: 'Company Name',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    change: 'Change',
    close: 'Close',
    seat: 'seat',
    seats: 'seats',
    month: 'month',
    months: 'months',
    monthly: 'monthly',
    perMonthPerSeat: 'per month per seat',
    oneTime: 'one time',
    training: 'training',
    trainings: 'trainings',
  },
  de: {
    next: 'Weiter',
    back: 'Zurück',
    tryAgain: 'Versuche es erneut',
    firstName: 'Vorname',
    lastName: 'Nachname',
    address: 'Adresse',
    email: 'Email',
    gender: 'Geschlecht',
    phone: 'Telefon',
    city: 'Stadt',
    zipCode: 'Postleitzahl',
    country: 'Land',
    state: 'Staat/Kanton/Provinz/Region',
    companyName: 'Firma',
    male: 'Männlich',
    female: 'Weiblich',
    other: 'Divers',
    change: 'Ändern',
    close: 'Schliessen',
    seat: 'Seat',
    seats: 'Seats',
    month: 'Monat',
    months: 'Monate',
    monthly: 'monatlich',
    perMonthPerSeat: 'pro Monat pro Seat',
    oneTime: 'einmal',
    training: 'Training',
    trainings: 'Trainings',
  },
};

export default general;
