import React from 'react';
import { useDispatch } from 'react-redux';
import { LanguageContext } from '../../../../providers/LanguageProvider';
import { addToCart, createOrder } from '../../../../store/entities/order';
import { CookieType } from '../../../../types/Cookie';
import { localization } from '../../../../assets/Localization';
import { getCookie } from '../../../../lib/cookieUtils';

interface AddToCartButtonProps {
  productCode: string;
  quantity: number;
  currency: string;
  className?: string;
  text?: string;
  paymentFrequency?: string | null;
}

const AddToCart: React.FC<AddToCartButtonProps> = ({ productCode, quantity, currency, className, text, paymentFrequency }) => {
  const dispatch = useDispatch();
  const { language } = React.useContext(LanguageContext);

  const addProductToCart = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (!productCode) return;
    const orderId = getCookie(CookieType.SHOPPING_CART);
    if (!orderId) {
      dispatch(createOrder({ productCode, quantity, currency, language, paymentFrequency }));
    } else {
      dispatch(addToCart({ orderId, productCode, quantity, currency, language, paymentFrequency }));
    }
  };

  return (
    <div>
      <a href="#" onClick={addProductToCart} className={className}>
        {text || localization[language].addToCart}
      </a>
    </div>
  );
};

export default AddToCart;
