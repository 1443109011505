import { takeLatest, all } from 'redux-saga/effects';

// handlers
import {
  handleGetDiagram,
  handleCreateNode,
  handleDeleteNode,
  handleUpdateNode,
  handleCreateConnection,
  handleUpdateConnection,
  handleDeleteConnection,
} from './handlers/diagram';
// actions
import {
  getDiagram,
  createNode,
  updateNode,
  deleteNode,
  createEdge,
  updateEdge,
  deleteEdge,
} from '../entities/diagram';

export function* watcherSaga() {
  yield all([
    takeLatest(getDiagram, handleGetDiagram),
    takeLatest(createNode, handleCreateNode),
    takeLatest(updateNode, handleUpdateNode),
    takeLatest(deleteNode, handleDeleteNode),
    takeLatest(createEdge, handleCreateConnection),
    takeLatest(updateEdge, handleUpdateConnection),
    takeLatest(deleteEdge, handleDeleteConnection),
  ]);
}
