import React, { memo } from 'react';

const KeyNodeData: React.FC = () => {
  return (
    <>
      <div>
        <p>(hidden)</p>
      </div>
    </>
  );
};

export default memo(KeyNodeData);
