/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react';
import { Block } from './CipherNodeData';

export const formatHex = (number: number): string => {
  number = number < 0 ? number + 256 : number;

  return (number < 16 ? '0' : '') + number.toString(16);
}

const AesDecryptNodeData: React.FC<{
  cipherTextBlocks: Block[];
  plainTextBlocks: Block[];
  plainTextString: string;
}> = ({ cipherTextBlocks, plainTextBlocks, plainTextString }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flex: 1,
        marginTop: 20,
      }}
    >
      {cipherTextBlocks?.map((block: any, index: number) => (
        <div
          key={index}
          style={{
            padding: 5,
            margin: 5,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ fontWeight: '700', marginBottom: 10 }}>block 0{index}</div>
          <div style={{ display: 'flex' }}>
            {block.slice(0, 8).map((element: number, index: number) => (
              <div key={index} style={{ margin: 2 }}>
                <span style={{ fontSize: 22 }}>{formatHex(element)}</span>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex' }}>
            {block.slice(8, 16).map((element: number, index: number) => (
              <div key={index + 8} style={{ margin: 2 }}>
                <span style={{ fontSize: 22 }}>{formatHex(element)}</span>
              </div>
            ))}
          </div>
          {index > 0 ? (
            <>
              {plainTextBlocks?.slice(index - 1, index).map((block: any, i: number) => (
                <div
                  key={i}
                  style={{
                    padding: 5,
                    margin: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ fontWeight: '700', marginBottom: 10 }}>block 0{index}</div>
                  <div style={{ display: 'flex' }}>
                    {block.slice(0, 8).map((element: number, i: number) => (
                      <div key={i} style={{ margin: 2 }}>
                        <span style={{ fontSize: 22 }}>{formatHex(element)}</span>
                      </div>
                    ))}
                  </div>
                  <div style={{ display: 'flex' }}>
                    {block.slice(8, 16).map((element: number, i: number) => (
                      <div key={i + 8} style={{ margin: 2 }}>
                        <span style={{ fontSize: 22 }}>{formatHex(element)}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          ) : null}
          {index === 1 && plainTextString ? (
            <>
              <div
                style={{
                  padding: 5,
                  margin: 5,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ fontWeight: '700', marginBottom: 10 }}>plain text ASCII</div>
                <div style={{ display: 'flex', margin: 2 }}>
                  <span style={{ fontSize: 22 }}>{plainTextString}</span>
                </div>
              </div>
            </>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default memo(AesDecryptNodeData);
