import { Diagram, DiagramConnection, DiagramNode, NodeType, nodeTypeLabel } from '../types';
import CustomNode from '../CustomComponents/Node/CustomNode';
import { Edge, NodeTypes } from 'reactflow';
import { marker } from '../assets/marker';
import { getUuid } from './uuidGenerator';

export const diagramMapper = (diagram: Diagram) => {
  const newNodeTypes: NodeTypes = {};
  const newNodes: DiagramNode[] = diagram.nodes.map((node) => {
    newNodeTypes[node.type] = CustomNode;
    return mapNodeForDiagram(node);
  });
  diagram.availableNodeTypes.forEach((type) => (newNodeTypes[type] = CustomNode));
  const edges = mapEdgesForDiagram(diagram.connections);
  return { ...diagram, nodeTypes: newNodeTypes, nodes: newNodes, edges };
};

export const mapNodeForDiagram = (node: DiagramNode) => {
  const newNode: DiagramNode = { ...node };
  newNode.data = { ...node, label: nodeTypeLabel[node.type] };
  if (node.type === NodeType.RESULT) {
    newNode.position = {
      x: 350,
      y: 1200,
    };
  }
  if (node.type === NodeType.KEY) {
    newNode.position = {
      x: 985,
      y: 465,
    };
  }
  if (node.type === NodeType.AES_DECRYPT) {
    newNode.position.y = 600;
  }

  return newNode;
};

export const mapEdgesForDiagram = (connections: DiagramConnection[]) => {
  const edges: Edge[] = [];
  connections.forEach((connection) => edges.push(mapSingleEdge(connection)));

  return edges;
};

export const mapSingleEdge = (connection: DiagramConnection) => {
  return {
    id: getUuid(),
    source: connection.sourceNodeId,
    target: connection.targetNodeId,
    sourceHandle: connection.sourcePortId,
    targetHandle: connection.targetPortId,
    type: connection.locked ? 'straight' : 'button_edge',
    markerEnd: marker.arrow,
    style: connection.locked
      ? marker.line
      : {
          ...marker.line,
          stroke: 'black',
        },
    deletable: !connection.locked,
    updatable: !connection.locked,
    selected: false,
  };
};
