/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react';
import CustomHandle from '../Handle/CustomHandle';
import { NodeType } from '../../types';
import {
  AesDecryptNodeData,
  AndNodeData,
  AsciiNodeData,
  CipherNodeData,
  KeyNodeData,
  NotNodeData,
  OrNodeData,
  ResultNodeData,
  XorNodeData,
} from './NodeData';
import classes from './custom-node.module.css';

const classMap: any = {
  [NodeType.CIPHER_TEXT_INPUT]: 'cipher-text-input-class',
  [NodeType.ASCII_INPUT]: 'ascii-input-class',
  [NodeType.AND]: 'and-class',
  [NodeType.OR]: 'or-class',
  [NodeType.NOT]: 'not-class',
  [NodeType.XOR]: 'xor-class',
  [NodeType.KEY]: 'key-class',
  [NodeType.AES_DECRYPT]: 'aes-decrypt-class',
  [NodeType.RESULT]: 'result-class',
};

const renderCustomNodeData = (type: NodeType, data: any) => {
  switch (type) {
    case NodeType.CIPHER_TEXT_INPUT:
      return <CipherNodeData blocks={data.props?.blocks || []} />;
    case NodeType.ASCII_INPUT:
      return <AsciiNodeData text={data.props?.text || ''} nodeId={data.id} position={data.position} />;
    case NodeType.AND:
      return <AndNodeData />;
    case NodeType.OR:
      return <OrNodeData />;
    case NodeType.NOT:
      return <NotNodeData />;
    case NodeType.XOR:
      return <XorNodeData />;
    case NodeType.KEY:
      return <KeyNodeData />;
    case NodeType.AES_DECRYPT:
      return <AesDecryptNodeData {...data.props} />;
    case NodeType.RESULT:
      return <ResultNodeData {...data.props} />;
    default:
      return <></>;
  }
};

const CustomNode: React.FC = (nodeInfo: any) => {
  const className = classMap[nodeInfo.data.type];
  return (
    <div key={nodeInfo.id}>
      {nodeInfo.data.inputPorts.length > 0 ? (
        <div className={classes['ports-wrapper']}>
          {nodeInfo.data.inputPorts.map((port: { id: string; locked: any }) => {
            return <CustomHandle key={`${port.id}`} id={port.id} isConnectable={!port.locked} isInput={true} />;
          })}
        </div>
      ) : null}
      <div
        className={`${classes['custom-node-wrapper']} ${classes[`${className}`]}`}
        style={{
          backgroundColor: nodeInfo.data.locked ? '#E2E5EA' : '#fff',
          border: `2px solid ${nodeInfo.data.locked ? '#BFC7d0' : nodeInfo.selected ? 'orange' : '#7BA5D0 '}`,
        }}
      >
        <label style={{ fontWeight: 'bold', margin: 5 }}>{nodeInfo.data.label}</label>
        {renderCustomNodeData(nodeInfo.data.type, nodeInfo.data)}
      </div>
      {nodeInfo.data.outputPorts.length > 0 ? (
        <div className={classes['ports-wrapper']}>
          {nodeInfo.data.outputPorts.map((port: { id: string; locked: boolean; property: string }) => {
            return <CustomHandle key={`${port.id}`} id={port.id} isConnectable={!port.locked} isInput={false} />;
          })}
        </div>
      ) : null}
    </div>
  );
};

export default memo(CustomNode);
