import { ApiService } from '../../../../../../services/ApiService';
import {
  CreateNodePayload,
  ConnectionPayload,
  UpdateConnectionPayload,
  ConnectionDataPayload,
  DeleteNodePayload,
  UpdateNodePayload,
  DeleteConnectionPayload,
} from '../../../types';

// Diagram request
export const requestCreateDiagram = () => {
  return ApiService.post('/diagrams/');
};

export const requestGetDiagram = (id: string) => {
  return ApiService.get(`/diagrams/${id}/`);
};

// Node requests
export const requestCreateNode = (payload: CreateNodePayload) => {
  return ApiService.post(`/diagrams/${payload.diagramId}/nodes/`, { type: payload.type, position: payload.position });
};

export const requestUpdateNode = (payload: UpdateNodePayload) => {
  return ApiService.put(`/diagrams/${payload.diagramId}/nodes/${payload.nodeId}`, {
    position: payload.position,
    props: payload.props,
  });
};

export const requestDeleteNode = (payload: DeleteNodePayload) => {
  return ApiService.delete(`/diagrams/${payload.diagramId}/nodes/${payload.nodeId}`);
};

// Connection requests
export const requestCreateConnection = (payload: ConnectionPayload) => {
  const data: ConnectionDataPayload = {
    sourcePortId: payload.sourcePortId,
    targetPortId: payload.targetPortId,
  };
  return ApiService.post(`/diagrams/${payload.diagramId}/connections/`, data);
};

export const requestUpdateConnection = (payload: UpdateConnectionPayload) => {
  const data = {
    originalConnection: payload.originalConnection,
    newConnection: payload.newConnection,
  };
  return ApiService.post(`/diagrams/${payload.diagramId}/connections/update`, data);
};

export const requestDeleteConnection = (payload: DeleteConnectionPayload) => {
  const data: ConnectionDataPayload = {
    sourcePortId: payload.sourcePortId,
    targetPortId: payload.targetPortId,
  };
  return ApiService.post(`/diagrams/${payload.diagramId}/connections/delete`, data);
};
