import { createSlice } from '@reduxjs/toolkit';
import { Edge, NodeTypes } from 'reactflow';
import { DiagramNode, NodeType } from '../../types';
import { mapNodeForDiagram, mapSingleEdge } from '../../utils/diagramMapper';

export type DiagramState = {
  id: string | null;
  nodes: DiagramNode[];
  nodeTypes: NodeTypes | undefined;
  availableNodeTypes: NodeType[];
  edges: Edge[];
  diagramLoading: boolean;
  nodeLoading: boolean;
  edgeLoading: boolean;
};

export interface DiagramStore {
  diagram: DiagramState;
}

const diagram = createSlice({
  name: 'diagram',
  initialState: {
    id: null,
    nodes: [],
    nodeTypes: undefined,
    availableNodeTypes: [],
    edges: [],
    diagramLoading: false,
    nodeLoading: false,
    edgeLoading: false,
    onNodesChange: () => {},
  } as DiagramState,
  reducers: {
    getDiagram(state, _action) {
      return { ...state, diagramLoading: true };
    },
    setDiagram(state, action) {
      const data = action.payload;
      return { ...state, ...data, diagramLoading: false, id: action.payload.id };
    },
    createNode(state, _action) {
      return { ...state, nodeLoading: true };
    },
    updateNode(state, _action) {
      return { ...state, nodeLoading: true };
    },
    deleteNode(state, _action) {
      return { ...state, nodeLoading: true };
    },
    setNodes(state, action) {
      const newNode = mapNodeForDiagram(action.payload);
      return { ...state, nodes: [...state.nodes, newNode], nodeLoading: false };
    },
    setDeleteNodes(state, action) {
      const filteredNodes = state.nodes.filter((node) => node.id !== action.payload.nodeId);
      return { ...state, nodes: [...filteredNodes], nodeLoading: false };
    },
    createEdge(state, _action) {
      return { ...state, edgeLoading: true };
    },
    updateEdge(state, _action) {
      return { ...state, edgeLoading: true };
    },
    deleteEdge(state, _action) {
      return { ...state, edgeLoading: true };
    },
    setEdges(state, action) {
      if (action.payload.edgeId) {
        const filteredEdges = state.edges.filter((edge) => edge.id !== action.payload.edgeId);
        return { ...state, edges: [...filteredEdges], edgeLoading: false };
      }
      const newEdge = mapSingleEdge(action.payload);
      return { ...state, edges: [...state.edges, newEdge], edgeLoading: false };
    },
    selectEdge(state, action) {
      const updateEdges = structuredClone(state.edges);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateEdges.forEach((e: any) => (e.selected = e.id === action.payload.id));
      return { ...state, edges: [...updateEdges], edgeLoading: false };
    },
  },
});

export const {
  setDiagram,
  getDiagram,
  createNode,
  setNodes,
  setDeleteNodes,
  updateNode,
  deleteNode,
  createEdge,
  updateEdge,
  deleteEdge,
  setEdges,
  selectEdge,
} = diagram.actions;

export default diagram.reducer;
