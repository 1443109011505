import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { updateNode } from '../../../store/entities/diagram';
import { XY } from '../../../types';
import { useParams } from 'react-router-dom';
import { sanitizeAscii } from '../../../utils/sanitizeAscii';

const AsciiNodeData: React.FC<{ text: string; nodeId: string; position: XY }> = ({ text, nodeId, position }) => {
  const dispatch = useDispatch();
  const params = useParams<string>();
  const [bytes, setBytes] = React.useState<number>(text.length);
  const [input, setInput] = React.useState<string>(text);
  const timerIdRef = React.useRef<number | NodeJS.Timeout>();
  const inputRef = React.useRef<HTMLInputElement>(null);

  // Clear the timeout on component unmount
  React.useEffect(() => {
    return () => {
      clearTimeout(timerIdRef.current);
    };
  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timerIdRef.current);
    setInput(event.target.value);
    setBytes(event.target.value.length);

    timerIdRef.current = setTimeout(() => {
      dispatch(
        updateNode({
          diagramId: params.id,
          nodeId: nodeId,
          position,
          props: { text: sanitizeAscii(event.target.value) },
        }),
      );
    }, 1000);
  };

  return (
    <div style={{ margin: 5, paddingBottom: 5 }}>
      <input
        ref={inputRef}
        id={nodeId}
        name="input-text-updater"
        value={input}
        onChange={onChange}
        style={{
          border: '2px solid black',
          height: 30,
          fontSize: 18,
        }}
      />
      <p
        style={{
          fontSize: 14,
          marginTop: 5,
          color: (bytes > 0 && bytes < 16) || bytes > 16 ? 'red' : 'black',
        }}
      >
        {bytes} {bytes !== 1 ? 'bytes' : 'byte'}
      </p>
    </div>
  );
};

export default memo(AsciiNodeData);
