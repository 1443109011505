import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Typography, Container, Stack, Box } from '@mui/material';
import { LanguageContext } from '../../../../providers/LanguageProvider';
import { getOrder } from '../../../../store/entities/order';
import { localization } from '../../../../assets/Localization';
import { formatCurrency } from '../../../../lib/formattingPrice';
import { randomNumber } from '../../../../lib/randomNumber';
import { formatQuantityTextSimple } from '../../../../lib/formatQuantityText';
import { OrderItem, OrderState } from '../../../../types/Order';
import { Loader } from '../../../common';
import classes from './Drawer.module.css';

const ShoppingCartList: React.FC<{ id: string | null }> = (props: { id: string | null }) => {
  const dispatch = useDispatch();
  const { id } = props;
  const { language } = React.useContext(LanguageContext);
  const order = useSelector((state: OrderState) => state.orders.order);

  React.useEffect(() => {
    if (id) dispatch(getOrder(id));
    return () => {};
  }, [dispatch, id]);

  return (
    <Container maxWidth="sm" className={classes.loader}>
      {!order ? (
        <Loader />
      ) : (
        <>
          <Stack spacing={1} marginX={1}>
            {order.items.map((item: OrderItem) => (
              <Paper
                key={`${item.id}-${randomNumber()}`}
                elevation={3}
                className={`${classes.paper} ${classes['border-bottom']} ${
                  item.status === 'REMOVED' ? classes['item-removed'] : ''
                }`}
              >
                <Box className={`${classes.box} ${classes['border-bottom']}`}>
                  <Typography>{item.product.translations[language].title}</Typography>
                  <Typography className={`${classes.bold} ${classes.center}`}>
                    {formatQuantityTextSimple(item.quantity, language, item.periodMonths)}
                  </Typography>
                </Box>
                {item.status !== 'REMOVED' ? (
                  <Box paddingBottom={1} className={classes['border-bottom']}>
                    <Typography className={`${classes.bold} ${classes.center}`}>
                      {formatCurrency(item.totalPrice, order.currency, false)} {order.currency}
                    </Typography>
                  </Box>
                ) : null}
              </Paper>
            ))}
            <Paper elevation={3} className={`${classes.total}`}>
              <Typography className={`${classes.bold} ${classes['right-padding']}`}>
                {localization[language].total}:
              </Typography>
              <Typography variant="h6" component="span" className={`${classes.bold}`}>
                {formatCurrency(order.totalPrice, order.currency, false)} {order.currency}
              </Typography>
            </Paper>
          </Stack>
        </>
      )}
    </Container>
  );
};

export default ShoppingCartList;
