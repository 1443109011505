import React, { useEffect, useRef } from 'react';

import { WidgetInstance } from 'friendly-challenge';
import { Loader } from '../Loader';

const Captcha = (props: { onSolution: (data: string) => void; siteKey: string; start?: boolean }) => {
  const captchaWidgetRef = useRef<HTMLDivElement>(null);
  const widget = useRef<WidgetInstance | null>(null);

  const { onSolution: setSolution, siteKey, start = true } = props;

  useEffect(() => {
    const el = captchaWidgetRef.current;
    if (el && start) {
      widget.current = new WidgetInstance(el, {
        doneCallback: (solution) => {
          setSolution(solution);
        },
        errorCallback: (solution) => {
          console.log(solution);
        },
      });
    }
    if (start && widget.current?.start) {
      widget.current.start();
    }
  }, [setSolution, start, siteKey]);

  if (!siteKey) return <Loader />

  return (
    <div
      className="frc-captcha"
      ref={captchaWidgetRef}
      data-sitekey={siteKey}
      data-start="none"
    ></div>
  );
};

export default Captcha;
